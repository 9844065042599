import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["order"];

  change_customer(event) {
    fetch(`/customers/${event.target.value}/finished-orders`)
      .then((response) => response.json())
      .then((json) => {
        const options = json
          .map(
            (option) => `<option value="${option.id}">Nº ${option.id}</option>`
          )
          .join("");

        this.orderTargets.forEach((order) => {
          order.innerHTML = options;
        });

        document
          .querySelector("template")
          .content.querySelector("select").innerHTML = options;
      });
  }
}
