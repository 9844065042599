import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["transferButton"];
  static values = { order: String };

  checked = [];

  addOrRemoveFromArray(array, value) {
    var index = array.indexOf(value);

    if (index === -1) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }
  }

  toggle(e) {
    const element = e.currentTarget;

    element.classList.toggle("checked");

    this.addOrRemoveFromArray(this.checked, element.dataset.product);

    this.transferButtonTargets.forEach(
      (button) =>
        (button.href = `/orders/new?order=${this.orderValue}&products=${this.checked}`)
    );
  }
}
