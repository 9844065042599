import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nfValue", "nfTotal", "orderTotal", "originalUrl"];

  connect() {
    if (this.hasNfTotalTarget) {
      this.show_total();
    }
  }

  formatMoney() {
    $(".money").mask("000.000.000.000.000,00", { reverse: true });
  }

  show_total() {
    const removeDots = (value) => value.replaceAll(".", "");
    const removeCommas = (value) => value.replaceAll(",", "");

    const getCents = (value) => parseInt(removeCommas(removeDots(value)));

    const totalNfCents = this.nfValueTargets.reduce(
      (total, target) => total + getCents(target.value),
      0
    );

    const formatedNfTotal = totalNfCents
      ? `${(totalNfCents / 100).toFixed(2)}`.replace(".", ",")
      : "0,00";

    this.nfTotalTarget.innerHTML = formatedNfTotal;

    const updatedTotal =
      Number(this.orderTotalTarget.dataset.original) - (totalNfCents || 0);

    const formatedOrderTotal = `${(updatedTotal / 100).toFixed(2)}`.replace(
      ".",
      ","
    );

    this.orderTotalTarget.innerHTML = formatedOrderTotal;

    this.originalUrlTarget.dataset["printUrl"] = `${
      this.originalUrlTarget.dataset["originalUrl"]
    }&nf=${totalNfCents || 0}`;
  }
}
