import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["total", "product", "shippingValue"];

  connect() {
    this.show_total();
  }

  show_total() {
    const removeDots = (value) => value.split(".").join("");
    const removeCommas = (value) => value.split(",").join("");

    const shippingValueCents = parseInt(
      removeCommas(removeDots(this.shippingValueTarget.value))
    );
    const totalCents = this.calculate_total() + shippingValueCents;
    const total = (totalCents / 100).toFixed(2);

    this.totalTarget.value = total;
    this.totalTarget.value = $(".total").masked();
  }

  calculate_total() {
    const products = this.productTargets.filter(
      (product) => product.closest(".nested-fields").style.display !== "none"
    );

    return products.reduce((total, product) => {
      const price = product.options[product.selectedIndex].dataset["price"];
      const quantity = product
        .closest(".nested-fields")
        .querySelector(".order-product-quantity").value;

      return total + price * quantity;
    }, 0);
  }

  change_customer(event) {
    fetch(`/customers/${event.target.value}/products`)
      .then((response) => response.json())
      .then((json) => {
        const options = json
          .map(
            (option) =>
              `<option data-price="${option.price_cents}" value="${option.id}">${option.complete_description}</option>`
          )
          .join("");

        this.productTargets.forEach((product) => {
          product.innerHTML = options;
        });

        document
          .querySelector("template")
          .content.querySelector("select").innerHTML = options;

        this.show_total();
      });
  }
}
