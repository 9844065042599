import { Controller } from "stimulus";

// 1 - Curvas: Crv. Virola
// 2 - Curvas: Crv. Laminada
// 3 - Curvas: Crv. HDF
// 4 - Portas: S/Oca Crú
// 5 - Portas: S/Oca Laminada
// 6 - Portas: S/Oca Ultra
// 7 - Portas: S/Sólida Crú
// 8 - Portas: S/Sólida Laminada
// 9 - Portas: S/Sólida Ultra
// 10 - Portas: S/Sólida Duratree
// 11 - Portas: Maciça Crú
// 12 - Portas: Maciça Laminada
// 13 - Portas: Maciça Ultra
// 14 - Mult. Virola
// 15 - Mult. Laminado
// 16 - Terciado
// 17 - Laminação
// 18 - Marchetaria

const typesSteps = [
  ["CORTE LAMINAS TORNEADAS", "COLAGEM CURVAS", "EMBALAGEM", "TRANSPORTADORA"],
  [
    "CORTE LAMINAS P/ CAPAS",
    "EMENDAÇÃO CAPAS",
    "COLAGEM CAPAS",
    "LIXAÇÃO CAPAS",
    "CORTE LAMINAS TORNEADAS",
    "COLAGEM CURVAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  ["CORTE HDF'S", "COLAGEM CURVAS", "EMBALAGEM", "TRANSPORTADORA"],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "CAPAS",
    "COLAGEM CAPAS",
    "LIXAÇÃO TERCIADOS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "CAPAS",
    "COLAGEM CAPAS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "LIXAÇÃO",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "CAPAS",
    "COLAGEM CAPAS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "LIXAÇÃO",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE HDF'S",
    "MONTAGEM QUADROS",
    "COLAGEM PORTAS",
    "CORTE PORTAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE LAMINAS TORNEADAS",
    "COLAGEM MULTILAMINAS",
    "CORTE MULTILAMINAS",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE LAMINAS TORNEADAS",
    "CAPAS",
    "COLAGEM MULTILAMINAS",
    "CORTE MULTILAMINAS",
    "LIXAÇÃO",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  [
    "CORTE LAMINAS TORNEADAS",
    "CAPAS",
    "COLAGEM TERCIADOS",
    "CORTE TERCIADOS",
    "LIXAÇÃO",
    "EMBALAGEM",
    "TRANSPORTADORA",
  ],
  ["CAPAS", "COLAGEM", "LIXAÇÃO", "EMBALAGEM", "TRANSPORTADORA"],
  ["CAPAS", "COLAGEM", "EMBALAGEM", "TRANSPORTADORA"],
];

export default class extends Controller {
  static targets = ["label", "ordination"];

  connect() {
    this.ordinate();
  }

  ordinate() {
    let ordination = 0;

    this.labelTargets.forEach((labelTarget, index) => {
      const isHidden =
        labelTarget.closest(".nested-fields").style.display === "none";

      if (!isHidden) {
        ordination += 1;

        labelTarget.innerHTML = `Passo ${ordination}`;
        this.ordinationTargets[index].value = ordination;
      }
    });
  }

  change_type(event) {
    const steps = Array.from(
      document.querySelectorAll(".nested-fields")
    ).filter((nestedField) => nestedField.style.display !== "none");

    const chosenSteps = typesSteps[event.target.value];

    if (steps.length < chosenSteps.length) {
      for (let i = chosenSteps.length - steps.length; i > 0; i--) {
        steps[steps.length - 1].querySelector("a").click();
      }
    } else if (steps.length > chosenSteps.length) {
      for (let i = steps.length - chosenSteps.length; i > 0; i--) {
        steps[steps.length - (1 + i)].querySelector("a").click();
      }
    }

    const updatedSteps = Array.from(
      document.querySelectorAll(".nested-fields")
    ).filter((nestedField) => nestedField.style.display !== "none");

    chosenSteps.forEach((chosenStep, index) => {
      updatedSteps[index].querySelector(
        "input[name*='description']"
      ).value = chosenStep;
    });
  }
}
