import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "template", "icon"];

  connect() {
    this.toggleIcons();
  }

  toggleIcons() {
    const iconLength = this.iconTargets.length;
    this.iconTargets.forEach((iconTarget, index) => {
      if (index === iconLength - 1) {
        iconTarget.dataset["action"] = iconTarget.dataset["action"].replace(
          "#remove_association",
          "#add_association"
        );
        iconTarget.innerHTML =
          '<img src="/images/icon-add.svg" width="21" alt="">';
      } else {
        iconTarget.dataset["action"] = iconTarget.dataset["action"].replace(
          "#add_association",
          "#remove_association"
        );
        iconTarget.innerHTML =
          '<img src="/images/icon-remove.svg" width="21" alt="">';
      }
    });
  }

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforebegin", content);

    this.toggleIcons();
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";

    this.toggleIcons();
  }
}
