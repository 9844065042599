import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "products", "modal"];

  toggle(event) {
    event.currentTarget.classList.toggle("checked");

    const selectedProducts = [];
    const selectedIds = [];

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.classList.contains("checked")) {
        selectedProducts.push(
          `<input type="hidden" name="products[]" value="${checkbox.dataset.product}" />`
        );
        selectedIds.push(checkbox.dataset.product);
      }
    });

    this.productsTarget.innerHTML = selectedProducts.join("");

    document.querySelectorAll(".update-print-url").forEach((printButton) => {
      printButton.dataset.printUrl =
        printButton.dataset.originalUrl +
        `?productIds=${selectedIds.join(",")}`;
    });
  }

  openModal() {
    this.modalTarget.style.display = "flex";
    this.modalTarget.style.opacity = 1;
  }
}
