$(document).on("turbolinks:load", () => {
  const buyerAdd = document.querySelector("#buyer-add");

  if (buyerAdd) {
    const buyerHidden = document.querySelector("#buyer-hidden");
    const buyerInput = document.querySelector("#buyer-input");
    const buyerResult = document.querySelector("#buyer-result");

    buyerAdd.addEventListener("click", (e) => {
      const newBuyer = buyerInput.value;

      if (newBuyer) {
        const currentBuyers =
          buyerHidden.value === "" ? [] : buyerHidden.value.split(", ");

        const updatedBuyers = [...currentBuyers, newBuyer].join(", ");

        buyerHidden.value = updatedBuyers;
        buyerResult.innerHTML = updatedBuyers;
        buyerInput.value = "";
      } else {
        alert("Novo comprador não pode ficar em branco!");
      }
    });
  }
});
